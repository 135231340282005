import React from 'react'
import ForwardLink from './ForwardLink'
import { List, ListItem, Link } from '../ui'

const Pagination = ({
  previousPagePath, nextPagePath, prevText = 'Előző oldal', nextText = 'Következő oldal',
}) => (
  <nav role="navigation">
    <List
      py={3}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {previousPagePath && (
        <ListItem mr={4}>
          <Link
            rel="prev"
            variant="buttons.text"
            to={previousPagePath}
          >
            {prevText}
          </Link>
        </ListItem>
      )}
      <ListItem>
        {nextPagePath && (
          <ForwardLink
            as={Link}
            rel="next"
            to={nextPagePath}
          >
            {nextText}
          </ForwardLink>
        )}
      </ListItem>
    </List>
  </nav>
)

export default Pagination
