export const createPaginatedPageTitle = (humanPageNumber = 1, title) => (
  humanPageNumber <= 1
    ? title
    : `${title} - ${humanPageNumber}. oldal`
)

export const createPaginatedMetaProps = (
  humanPageNumber = 1,
  { title, description, opengraphImage },
) => ({
  opengraphImage,
  title: humanPageNumber <= 1
    ? title
    : `${title} - ${humanPageNumber}. oldal`,
  description: humanPageNumber <= 1
    ? description
    : `${description} - ${humanPageNumber}. oldal`,
})
